<template>
  <v-app id="inspire">
    <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" max-height="1000">

    </v-sheet>
  </v-app>
</template>
<script>
  export default {
    components: {

    },

    data: () => ({
      icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ],
    }),
    methods: {
      dash1() {
        this.loading = true;
        setTimeout(() => {
          this.$router.push("/dashboard/dashboard1");
        }, 1000);
      },
      dash2() {
        this.loading = true;
        setTimeout(() => {
          this.$router.push("/dashboard/dashboard2");
        }, 1000);
      },
      dash3() {
        this.loading = true;
        setTimeout(() => {
          this.$router.push("/dashboard/dashboard3");
        }, 1000);
      },

    }
  };
</script>
